<template>
  <van-skeleton title :row="20" />
</template>

<script>
import { Skeleton } from "vant";

export default {
  name: "",
  components: {
    [Skeleton.name]: Skeleton,
  },
  async mounted() {
    this.$router.push("/home");
  },
};
</script>
