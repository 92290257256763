import { createRouter, createWebHashHistory } from "vue-router";
import Root from "../views/Root.vue";
const Home = import("../views/Home.vue");
const About = import("../views/About.vue");
const Settings = import("../views/Settings.vue");
const RemoteController = import("../views/RemoteController.vue");

const routes = [
  {
    path: "/",
    name: "",
    component: Root,
  },
  {
    path: "/home",
    name: "Home",
    component: () => Home,
  },
  {
    path: "/remote_controller",
    name: "Remote Controller",
    component: () => RemoteController,
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => Settings,
  },
  {
    path: "/about",
    name: "About",
    component: () => About,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
