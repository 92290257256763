import store from "@/store";

export const CMD_PING = 0x00;
export const CMD_VERSION = 0x01;
export const CMD_CHANGEEXPRESSION = 0x02;
export const CMD_READEXPRESSION_DATA = 0x03;
export const CMD_WRITEEXPRESSION_DATA = 0x04;
export const CMD_SET_WIFI_NAME = 0x05;
export const CMD_SET_WIFI_PASSWORD = 0x06;
export const CMD_GET_WIFI_STATUS = 0x07;
export const CMD_WIFI_CONNECT = 0x08;
export const CMD_DOWNLOAD_MODE = 0xf0;
export const CODE_SUCCESS = 0x00;
export const CODE_FAILED = 0x01;
export const CODE_CMD_NOT_SUPPORT = 0xfe;
export const CODE_UNKNOWN_ERROR = 0xff;

const BOARDCAST_TYPE = 0xafac;
const CONTROL_SERVICE_UUID = "7c1ce676-42f4-4972-b589-2bd94c702b91";
const EXPRESSION_CHAR = "e3a0d772-1452-472e-8f76-10f86781a8e3";

/**
 * @param {Uint8Array} array
 * @returns {String}
 */
export const asciiToString = (array) => {
  let str = "";
  array.forEach((num) => {
    if (num < 0 || num >= 128) {
      throw Error("not a ascii array");
    }
    str = str.concat(String.fromCharCode(num));
  });
  return str;
};

/**
 * @param {String} str
 * @returns {Uint8Array}
 */
export const stringToAscii = (str) => {
  let array = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    let code = str.charCodeAt(i);
    if (code < 0 || code >= 128) {
      throw Error("not a ascii string");
    }
    array[i] = code;
  }
  return array;
};

export const init = async () => {
  let available = true;
  // getAvailability only works on PC
  // if (navigator.bluetooth != undefined) {
  //   available = await navigator.bluetooth.getAvailability();
  //   navigator.bluetooth.addEventListener("availabilitychanged", async () => {
  //     let available = await navigator.bluetooth.getAvailability();
  //     store.commit("bleAvailable", available);
  //   });
  // }
  // console.log("bleAvailavle:", available);
  store.commit("bleAvailable", available);
};

export const connect = async () => {
  try {
    if (store.state.bleAvailable) {
      let device;
      if (store.state.bleDevice == undefined) {
        device = await navigator.bluetooth.requestDevice({
          filters: [
            {
              services: [BOARDCAST_TYPE],
            },
          ],
          optionalServices: [CONTROL_SERVICE_UUID],
        });
        device.addEventListener("gattserverdisconnected", disconnect);
      } else {
        device = store.state.bleDevice;
      }
      let server = await device.gatt.connect();
      let service = await server.getPrimaryService(CONTROL_SERVICE_UUID);
      let characteristic = await service.getCharacteristic(EXPRESSION_CHAR);
      store.commit("bleConnect", {
        bleDevice: device,
        bleCharacteristic: characteristic,
      });
      return true;
    }
  } catch (err) {
    return Promise.reject(err);
  }
  return Promise.reject();
};

export const disconnect = () => {
  if (store.state.bleDevice?.gatt?.connected) {
    store.state.bleDevice.gatt.disconnect();
  }
  store.commit("bleConnect", {
    bleDevice: store.state.bleDevice,
    bleCharacteristic: undefined,
  });
};

export const reset = async () => {
  if (store.state.bleDevice?.gatt?.connected) {
    store.state.bleDevice.gatt.disconnect();
  }
  store.commit("bleConnect", {
    bleDevice: undefined,
    bleCharacteristic: undefined,
  });
  return Promise.resolve();
};

/**
 * write data to protogen chars.
 * @param {Array<number>|Uint8Array|Uint8ClampedArray} data
 * @returns {Promise<Uint8ClampedArray>}
 */
export const writeData = (data) => {
  if (store.state.bleCharacteristic != undefined) {
    return new Promise((resolve, reject) => {
      const bleListener = async (ev) => {
        await unregister();
        resolve(new Uint8ClampedArray(ev.target.value.buffer));
      };
      const unregister = async () => {
        if (store.state.bleCharacteristic != undefined) {
          store.state.bleCharacteristic.removeEventListener(
            "characteristicvaluechanged",
            bleListener
          );
          await store.state.bleCharacteristic.stopNotifications();
        }
      };
      const send = async () => {
        try {
          store.state.bleCharacteristic.addEventListener(
            "characteristicvaluechanged",
            bleListener
          );
          await store.state.bleCharacteristic.startNotifications();
          await store.state.bleCharacteristic.writeValue(new Uint8Array(data));
        } catch {
          await unregister();
        }
      };
      send().catch((e) => {
        reject(e);
      });
    });
  }
  return Promise.reject(undefined);
};

/* operation functions */

export const cmdChangeExpression = async (index) => {
  if (index < 0 || index >= 256) {
    throw new Error("index out of range.");
  }
  let recv = await writeData([CMD_CHANGEEXPRESSION, index]);
  let [code] = recv;
  if (code != CODE_SUCCESS) {
    throw new Error("remote write failed");
  }
};

export const cmdConfigWifi = async (name, password) => {
  let recv = await writeData([CMD_SET_WIFI_NAME, ...stringToAscii(name)]);
  let [code] = recv;
  if (code != CODE_SUCCESS) {
    throw new Error("remote write failed");
  }
  recv = await writeData([CMD_SET_WIFI_PASSWORD, ...stringToAscii(password)]);
  [code] = recv;
  if (code != CODE_SUCCESS) {
    throw new Error("remote write failed");
  }
};

export const cmdConnectWifi = async () => {
  let recv = await writeData([CMD_WIFI_CONNECT]);
  let [code] = recv;
  if (code != CODE_SUCCESS) {
    throw new Error("remote write failed");
  }
};

export const cmdGetWifiStatus = async () => {
  let recv = await writeData([CMD_GET_WIFI_STATUS]);
  let [code, ...data] = recv;
  if (code != CODE_SUCCESS) {
    throw new Error("remote read failed");
  }
  return asciiToString(data);
};

export const cmdEnterDownloadMode = async () => {
  let recv = await writeData([CMD_DOWNLOAD_MODE]);
  let [code] = recv;
  if (code != CODE_SUCCESS) {
    throw new Error("remote write failed");
  }
};
