import localForage from "localforage";
import { t } from "@/utils/translate";
import { createStore } from "vuex";

const KEY_EXPRESSION_DATA = "expression_data";
const KEY_EXPRESSION_META = "expression_meta";
const KEY_SHOW_EDIT_HINT = "show_edit_hint";

export default createStore({
  state: {
    bleAvailable: false,
    bleDevice: undefined,
    bleCharacteristic: undefined,
    expressionData: new Uint8ClampedArray(128 * 256), //save
    expressionMeta: [], //save
    showEditHint: true, //save
    navTitle: "",
  },
  mutations: {
    bleAvailable(state, value) {
      state.bleAvailable = value;
    },
    bleConnect(state, { bleDevice, bleCharacteristic }) {
      state.bleDevice = bleDevice;
      state.bleCharacteristic = bleCharacteristic;
    },
    navTitle(state, title) {
      state.navTitle = title;
    },
    expressionData(state, value) {
      state.expressionData = value;
    },
    expressionMeta(state, value) {
      state.expressionMeta = value;
    },
    showEditHint(state, value) {
      state.showEditHint = value;
    },
  },
  actions: {
    async init(store) {
      // read store data
      let exp_data =
        (await localForage.getItem(KEY_EXPRESSION_DATA)) ??
        new Uint8ClampedArray(128 * 256);
      let exp_meta = await localForage.getItem(KEY_EXPRESSION_META);
      if (exp_meta == null) {
        // init meta data
        exp_meta = [];
        for (let i = 0; i < 256; i++) {
          exp_meta[i] = {
            name: t("expression") + " " + i.toString(),
            index: i,
            preview: null,
          };
        }
      }
      let showEditHint =
        (await localForage.getItem(KEY_SHOW_EDIT_HINT)) ?? true;
      store.commit("expressionData", exp_data);
      store.commit("expressionMeta", exp_meta);
      store.commit("showEditHint", showEditHint);
    },
    async saveExpressionData(store, exp_data) {
      await localForage.setItem(KEY_EXPRESSION_DATA, exp_data);
      store.commit("expressionData", exp_data);
    },
    async saveExpressionMeta(store, exp_meta) {
      // clear proxy
      let newMeta = [];
      exp_meta.forEach((meta) => {
        newMeta.push({ ...meta });
      });
      await localForage.setItem(KEY_EXPRESSION_META, newMeta);
      store.commit("expressionMeta", newMeta);
    },
    async saveShowEditHint(store, showEditHint) {
      await localForage.setItem(KEY_SHOW_EDIT_HINT, showEditHint);
      store.commit("showEditHint", showEditHint);
    },
  },
  modules: {},
});
