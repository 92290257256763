import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { init as bleinit } from "./utils/bluetoothhelper";
// import VConsole from "vconsole";
// var vConsole = new VConsole();
// console.log(vConsole);

// setup
bleinit();
store.dispatch("init");
window["store"] = store;

// create app
createApp(App).use(store).use(router).mount("#app");
