const LOCAL_STORAGE_KEY_LOCAL = "config.local";
export const LOCAL_EN = "en";
export const LOCAL_ZH = "zh-CN";
// DICT
const dict = {
  ["document_title"]: {
    default: "Protogen Controller",
    ["zh-CN"]: "Protogen控制器",
  },
  ["confirm"]: {
    default: "Confirm",
    ["zh-CN"]: "确认",
  },
  ["cancel"]: {
    default: "Cancel",
    ["zh-CN"]: "取消",
  },
  ["home"]: {
    default: "Home",
    ["zh-CN"]: "主页",
  },
  ["settings"]: {
    default: "Settings",
    ["zh-CN"]: "设置",
  },
  ["remote_controller"]: {
    default: "Remote Controller",
    ["zh-CN"]: "遥控器",
  },
  ["expressions"]: {
    default: "Expressions",
    ["zh-CN"]: "表情",
  },
  ["expression"]: {
    default: "Expression",
    ["zh-CN"]: "表情",
  },
  ["ptg_connected"]: {
    default: "Protogen Connected.",
    ["zh-CN"]: "Protogen已连接",
  },
  ["ptg_disconnected"]: {
    default: "Protogen Disconnected.",
    ["zh-CN"]: "Protogen已失联",
  },
  ["ptg_connecting"]: {
    default: "Connecting Protogen...",
    ["zh-CN"]: "正在连接Protogen...",
  },
  ["ptg_connect_failed"]: {
    default: "Connect Protogen failed.",
    ["zh-CN"]: "连接Protogen失败",
  },
  ["id"]: {
    default: "ID",
    ["zh-CN"]: "序号",
  },
  ["name"]: {
    default: "Name",
    ["zh-CN"]: "名称",
  },
  ["preview"]: {
    default: "Preview",
    ["zh-CN"]: "预览",
  },
  ["edit"]: {
    default: "Edit",
    ["zh-CN"]: "编辑",
  },
  ["empty"]: {
    default: "Empty",
    ["zh-CN"]: "空",
  },
  ["successed"]: {
    default: "Successed",
    ["zh-CN"]: "成功",
  },
  ["failed"]: {
    default: "Failed",
    ["zh-CN"]: "失败",
  },
  ["upload"]: {
    default: "Upload",
    ["zh-CN"]: "上传",
  },
  ["download"]: {
    default: "Download",
    ["zh-CN"]: "下载",
  },
  ["wifi_config_requirement"]: {
    default:
      'WIFI configuration can only contains letters, numbers and "-_", and no more than 19 characters',
    ["zh-CN"]: 'WIFI配置中只能出现字母、数字和"-_", 并且长度不超过19个字符',
  },
  ["wifi_not_connected"]: {
    default: "WIFI not connected",
    ["zh-CN"]: "WIFI未连接",
  },
  ["wifi_is_connecting"]: {
    default:
      "WIFI is Connecting...\nThis may take up to 5 minutes\nWrong configuration may lead to connecting forever",
    ["zh-CN"]: "WIFI正在连接\n这大概需要五分钟\n配置错误会导致一直在连接状态",
  },
  ["wifi_connected"]: {
    default: "WIFI connected",
    ["zh-CN"]: "WIFI已连接",
  },
  ["entered_download_mode"]: {
    default: "Entered download mode",
    ["zh-CN"]: "已进入下载模式",
  },
  ["wifi_status_wrong_password"]: {
    default: "WIFI wrong password",
    ["zh-CN"]: "WIFI密码错误",
  },
  ["wifi_status_no_ap"]: {
    default: "WIFI not found",
    ["zh-CN"]: "WIFI未找到",
  },
  ["wifi_status_unknown"]: {
    default: "WIFI status unknown\nPlease reboot Protogen",
    ["zh-CN"]: "WIFI状态异常\n请重启Protogen",
  },
  ["wifi_name"]: {
    default: "WIFI Name",
    ["zh-CN"]: "WIFI名称",
  },
  ["wifi_password"]: {
    default: "WIFI Pass",
    ["zh-CN"]: "WIFI密码",
  },
  ["update_wifi_configuration"]: {
    default: "Update WIFI Configuration",
    ["zh-CN"]: "更新WIFI配置",
  },
  ["connect_wifi"]: {
    default: "Connect to WIFI",
    ["zh-CN"]: "连接到WIFI",
  },
  ["wifi_status"]: {
    default: "WIFI Status",
    ["zh-CN"]: "WIFI状态",
  },
  ["enter_download_mode"]: {
    default: "Enter Download Mode",
    ["zh-CN"]: "进入下载模式",
  },
};

// utils
const getLocalStorage = (name, defaultValue) => {
  let value = localStorage.getItem(name);
  if (value === null) {
    return defaultValue;
  } else {
    return value;
  }
};

// translate function
const config = {
  local: getLocalStorage(
    LOCAL_STORAGE_KEY_LOCAL,
    navigator.language || navigator.userLanguage
  ),
};

export const setLocal = (local) => {
  config.local = local;
  localStorage.setItem(LOCAL_STORAGE_KEY_LOCAL, local);
};

export const t = (name) => {
  let item = dict[name];
  if (item === undefined) {
    return "[No Text]";
  }
  if (item[config.local] !== undefined) {
    return item[config.local];
  } else {
    return item.default;
  }
};
