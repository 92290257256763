<template>
  <div id="screen_content">
    <div id="page_navbar">
      <NavBar />
    </div>
    <div id="page_content">
      <router-view />
    </div>
  </div>
</template>

<style scoped>
#screen_content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
#page_navbar {
  flex: 0 0 auto;
  overflow: hidden;
}
#page_content {
  flex: 1 1 auto;
  overflow: auto;
}
</style>

<script>
import "vant/lib/icon/local.css";
import NavBar from "@/components/NavBar";

export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>
