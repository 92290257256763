<template>
  <van-nav-bar :title="$store.state.navTitle">
    <template #left>
      <van-icon
        id="btn_back"
        v-if="showBackButton"
        name="arrow-left"
        size="24"
        @click="$router.back()"
      />
      <van-icon
        name="wap-home-o"
        size="24"
        @click="$router.replace({ path: '/home' })"
      />
    </template>
    <template #right>
      <van-icon
        v-if="!bleConnected"
        name="warning"
        size="24"
        color="orange"
        @click="bconnect()"
      />
      <van-icon
        v-if="bleConnected"
        name="checked"
        size="24"
        color="green"
        @click="breset()"
      />
    </template>
  </van-nav-bar>
</template>

<style scoped>
#btn_back {
  margin-right: 16px;
}
</style>

<script>
import router from "@/router";
import { t } from "@/utils/translate";
import { connect, reset } from "@/utils/bluetoothhelper";
import { NavBar, Icon, Toast } from "vant";
let inited = false;

export default {
  name: "App",
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },
  computed: {
    bleConnected() {
      if (inited) {
        if (this.$store.state.bleCharacteristic) {
          Toast.success(t("ptg_connected"));
        } else {
          Toast.fail(t("ptg_disconnected"));
        }
      } else {
        inited = true;
      }
      return this.$store.state.bleCharacteristic != undefined;
    },
  },
  data() {
    return {
      showBackButton: false,
    };
  },
  methods: {
    async bconnect() {
      Toast.loading({
        message: t("ptg_connecting"),
        forbidClick: true,
        duration: 0,
      });
      try {
        await connect();
        // clear toast by computed function bleConnected.
      } catch (e) {
        Toast.fail(t("ptg_connect_failed"));
        console.error(e);
      }
    },
    async breset() {
      await reset();
    },
  },
  mounted() {
    router.afterEach((to) => {
      if (["Expression"].includes(to.name)) {
        this.showBackButton = true;
      } else {
        this.showBackButton = false;
      }
    });
  },
};
</script>
